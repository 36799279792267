var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("VueJsonPretty", {
    attrs: {
      "show-select-controller": "",
      "show-length": "",
      "highlight-mouseover-node": "",
      data: _vm.jsonData,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }